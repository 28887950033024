import React from 'react'
import { useIntl } from "gatsby-plugin-intl"
import {Link} from 'gatsby'

const NotFoundPage = () => {
    const intl = useIntl()
    return (
      <section className="page_404">
        <div className="container">
          <div className="row">	
            <div className="col-sm-12">
              <div className="col-sm-10 col-sm-offset-1 text-center">
                  <div className="four_zero_four_bg">
                    <h1 className="text-center ">404</h1>
                  </div>
                
                <div className="contant_box_404">
                  <h3 className="h2">
                  {intl.formatMessage({ id: "NotFoundPage.Title" })}
                  </h3>
                  
                  <p>{intl.formatMessage({ id: "NotFoundPage.Content" })}</p>
                  
                  <Link to="/" className="link_404">{intl.formatMessage({ id: "NotFoundPage.Redirect" })}</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default NotFoundPage